<template>
  <div class="product container" :class="{loading: loading}">
    <Breadcrumbs/>
    <div class="base-info">
      <ImagesViewer :images="images" />
      <div class="info">
        <AlertBar :key="alertBarKey" />
        <h1>{{name}}</h1>
        <div class="variants" v-if="variants.length" :class="{'logged-out': !isLoggedIn}">
          <span class="name title">Productnaam</span>
          <span class="variant-name title">Variantnaam</span>
          <span v-if="showPrices && isLoggedIn" class="price title" title>Prijs</span>
          <span v-if="isLoggedIn" class="input title">Aantal</span>
          <template v-for="variant of variants">
            <span class="name" :key="variant.id + '-span'">{{name}}</span>
            <span class="variant-name" :key="variant.id + '-variant'">{{variant.name}}</span>
            <span v-if="variant.price && showPrices && isLoggedIn" :key="variant.id + '-price'" class="price">{{variant.price}}</span>
            <div v-if="isLoggedIn" :key="variant.id + '-input'" class="input">
              <input type="number" v-model="chosenAmounts[variant.id]" :name="'variation[' + variant.id +']'" min="0"/>
            </div>
          </template>
          <div v-if="isLoggedIn"></div>
          <div v-if="isLoggedIn"></div>
          <div v-if="isLoggedIn"></div>
          <input v-if="isLoggedIn" type="button" @click="addToCart" value="Toevoegen" class="button">
        </div>
      </div>
    </div>
    <tabs :options="{ useUrlFragment: false, defaultTabHash: activeTab }" @changed="onTabChanged" class="extra-info">
      <tab name="Beschrijving" id="description" class="description" v-html="description">
      </tab>
      <tab name="Adviesprijzen" id="advised-prices" class="advised-prices" v-if="showPrices && isLoggedIn">
        <table>
          <tr v-for="variant of variants" :key="variant.id">
            <td>{{name}} - {{ variant.name }}</td>
            <td>{{ variant.advised_price }}</td>
          </tr>
        </table>
      </tab>
    </tabs>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Basic/Breadcrumbs";
import API from "@/libs/API";
import store from "@/store";
import ImagesViewer from "@/components/Basic/ImagesViewer";
import localization from "@/libs/Localization";
import AlertBar from "@/components/Alerts/AlertBar";

export default {
  name: "Product",
  components: {AlertBar, ImagesViewer, Breadcrumbs},
  data() {
    return {
      id: 1,
      images: [],
      name: undefined,
      sku: undefined,
      price: undefined,
      price_advice: undefined,
      description: undefined,
      variants: [],
      chosenAmounts: {},
      activeTab: '#description',
      loading: true,
      alertBarKey: 0,
    }
  },
  created() {
    this.refreshProduct();
  },
  computed: {
    isLoggedIn(){
      return API.auth.isLoggedIn();
    },
    showPrices() {
      return store.getters['main/showPrices'];
    }
  },
  methods: {
    onTabChanged(selectedTab) {
      this.activeTab = selectedTab.tab.hash;
    },
    async refreshProduct() {
      this.loading = true;
      const id = this.$route.params.slug;
      const response = await API.products.get(id);
      const product = response.data.product;

      this.id = product.id;
      this.images = product.images.length > 0 ? product.images : [product.image];
      this.name = product.name;
      this.description = product.description;
      this.variants = this.parseVariants(product.products);
      this.loading = false;
    },
    async addToCart() {
      this.loading = true;
      const response = await API.cart.addGroup(this.chosenAmounts);
      await store.dispatch('cart/refreshCart');
      if ( response.status === 200 ) {
        if ( response.data.messages.length > 0 ) {
          await store.dispatch('messages/addErrorMessage', response.data.messages.join(', '));
        } else {
          await store.dispatch('messages/addSuccessMessage', 'Artikel toegevoegd aan winkelwagen!');
        }
      } else {
        await store.dispatch('messages/addErrorMessage', 'Er ging iets mis! Controlleer of de aantallen goed zijn ingevuld.');
      }
      this.alertBarKey++;
      this.loading = false;
    },
    parseVariants(variants) {
      const result = [];

      for (const variant of variants) {
        result.push({
          id: variant.id,
          name: variant.name,
          price: localization.prices.format(variant.price),
          advised_price: localization.prices.format(variant.advised_price)
        });
      }

      return result;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/product.scss";
</style>