<template>
  <div class="search container">
    <ProductsOverview
        v-if="totalProducts !== undefined"
        :lastPage="lastPage"
        :possibleSorts="possibleSorts"
        :products="products"
        :totalProducts="totalProducts"
        v-model="productsOverviewConfig"
        :count="count"
        selected-sort-order=""
    />
  </div>
</template>

<script>
import ProductsOverview from "@/components/Product/ProductsOverview";
import _ from "lodash";
import API from "@/libs/API";
import localization from "@/libs/Localization";
export default {
  name: "Search",
  components: {ProductsOverview},
  data() {
    return {
      totalProducts: undefined,
      lastPage: undefined,
      possibleSorts: [],
      products: [],
      productsOverviewConfig: {
        page: 1
      },
      count: undefined,
      throttledRefreshProducts: _.throttle(() => { this.refreshProducts() }, 1000),
    }
  },
  computed: {
    query() {
      return this.$route.query.q;
    }
  },
  created() {
    this.throttledRefreshProducts();
  },
  watch: {
    productsOverviewConfig: {
      handler() {
        this.throttledRefreshProducts();
      },
      deep: true
    },
    query() {
      this.throttledRefreshProducts();
    }
  },
  methods: {
    async refreshProducts() {
      const response = await API.products.search(this.query, this.productsOverviewConfig.page);
      const pagination = response.data;

      this.page = parseInt(pagination.current_page);
      this.totalProducts = parseInt(pagination.total);
      this.count = parseInt(pagination.per_page);
      this.lastPage = parseInt(pagination.last_page);

      const products = [];
      for (const product of pagination.data) {
        products.push({
          id: product.id,
          image: product.image === null ? '' : product.image.url,
          name: product.name,
          price: localization.prices.parseMinMaxPrice(product.min_price, product.max_price),
          slug: product.slug,
        })
      }
      this.products = products;
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/pages/catalog/search.scss';
</style>