import base from "@/libs/API/partials/base";

const products = {
    async byTag(tag) {
        const url = base.getUrl("product/tag/" +  tag);
        return await base.get(url);
    },
    async get(id) {
        const url = base.getUrl("product/"+id);
        return await base.get(url);
    },
    async search(q, page = 1) {
        const params = (new URLSearchParams({q: q, page: page})).toString();
        const url = base.getUrl('product/search/?' + params);
        return await base.get(url);
    },

    async latest() {
        const url = base.getUrl('product/latest');
        return await base.get(url);
    },
}

export default products;