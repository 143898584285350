import base from "@/libs/API/partials/base";

const categoriesAPI = {
    async getAll() {
        const url = base.getUrl("category") + '?exclude_header_categories=1';
        return await base.get(url);
    },



    async header() {
        const url = base.getUrl("category/header");
        return await base.get(url);
    },

    async get(id, filters = {}) {
        let url = 'category/' + id;

        const params = (new URLSearchParams(filters)).toString();

        if ( params.length > 0 ) {
            url += '?' + params;
        }

        url = base.getUrl(url);
        return await base.get(url);
    },

    async getSubcategories(slug) {
        const url = base.getUrl('category/'+slug+'/subcategories');
        return await base.get(url);
    }
}

export default categoriesAPI;

