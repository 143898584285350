<template>
  <div class="orders">
    <h1>Mijn bestellingen</h1>
    <table v-if="orders.length > 0">
      <thead>
        <tr>
          <th>Bestelnummer</th>
          <th>datum</th>
          <th>Status</th>
          <th>Bedrag</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order of orders" :key="order.id" class="order">
          <td>
            {{ order.id }}
          </td>
          <td>
            {{ order.createdAt }}
          </td>
          <td>
            {{ order.orderStatus }}
          </td>
          <td>
            <template v-if="showPrices">
              {{ order.total }}
            </template>
          </td>
          <td>
            <router-link :to="{name: 'AccountOrder', params: {id: order.id}}" class="button view">Bekijken</router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>Je hebt nog geen bestellingen gedaan!</p>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Orders",
  created() {
    store.dispatch('customer/initializeCustomer');
  },
  computed: {
    orders() {
      return store.getters['customer/orders'];
    },
    showPrices() {
      return store.getters['main/showPrices'];
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/account/orders.scss";
</style>