<template>
  <div class="images-viewer" v-if="images.length > 0">
    <div class="images-scroll">
      <img
          v-for="(image, index) of images"
          :key="image.id"
          @click="showImage(index)"
          :src="image.url"
          :alt="image.title"
          :class="{chosen: chosen === index}"
      />
    </div>
    <div class="image">
      <img :src="images[chosen].url" :alt="images[chosen].title" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagesViewer",
  data() {
    return {
      chosen: 0
    }
  },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  methods: {
    showImage(index) {
      this.chosen = index;
    }
  }
}
</script>

<style scoped>

</style>