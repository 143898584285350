import API from "@/libs/API";
import localization from "@/libs/Localization";

const state = () => {
    return {
        cart: {
            items: [],
            price: 0,
            tax: 0,
            originalCount: 0,
            initialized: false,
            canCheckout: false,
            minimalOrderAmount: false
        },
    };
};

// getters
const getters = {
    items: state => state.cart.items,
    count: state => state.cart.originalCount,
    price: state => localization.prices.format(state.cart.price),
    tax: state => localization.prices.format(state.cart.tax),
    subtotal: state => localization.prices.format(state.cart.price - state.cart.tax),
    canCheckout: state => state.cart.canCheckout,
    minimalOrderAmount: state => localization.prices.format(state.cart.minimalOrderAmount),
    rawCartPrice: state => state.cart.price,
    initialized: state => state.cart.initialized,
    isEmpty: state => getters.count(state) === 0
};

// actions
const actions = {
    async initializeCart({commit}) {
        commit("SET_INITIALIZED");
        await actions.refreshCart({commit});
    },
    async refreshCart({commit}) {
        const cart = await API.cart.get();
        commit("SET_CART", {cart: cart.data});
    }
};

// mutations
const mutations = {
    ["SET_CART"](state, {cart}) {
        state.cart.items = parseItems(cart.items);
        state.cart.price = cart.price;
        state.cart.tax = cart.tax;

        let count = 0;
        for (const item of state.cart.items) {
            count += parseInt(item.quantity);
        }
        state.cart.originalCount = count;
        state.cart.canCheckout = cart.can_checkout;
        state.cart.minimalOrderAmount = cart.minimal_order_amount;
    },
    ["SET_INITIALIZED"](state) {
        state.cart.initialized = true;
    }
};

function parseItems(items) {
    const result = [];

    for(const item of items) {
        result.push({
            id: item.id,
            product: {
                id: item.product.id,
                image: item.product.shown_image,
                price: localization.prices.format(item.product.price),
                name: item.product.name,
                sku: item.product.sku,
            },
            quantity: item.quantity,
            subtotal: localization.prices.format(item.product.price * item.quantity)
        });
    }

    return result;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};