import Vuex from "vuex";
import Vue from "vue";
import main from "./stores/main";
import cart from "@/store/stores/cart";
import customer from "@/store/stores/customer";
import messages from "@/store/stores/messages";
import models from "@/store/stores/models";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        main,
        cart,
        customer,
        messages,
        models
    }
});

export function useStore() {
    return store;
}

export default store;