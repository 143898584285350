<template>
  <div class="header">
    <TopHeader />
    <MiddleHeader />
    <Menu />
  </div>
</template>

<script>
import TopHeader from "./Header/TopHeader";
import MiddleHeader from "./Header/MiddleHeader";
import Menu from "./Header/Menu";
export default {
  name: "Header",
  components: {Menu, MiddleHeader, TopHeader}
}
</script>

<style lang="scss">
@import "@/scss/variables.scss";
.header {
  grid-area: header;
  box-shadow: 0 0 5px -2px $background-dark;
  z-index: 2;
}
</style>