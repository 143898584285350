<template>
  <div class="login container" :class="{loading: loading}">
    <form class="login-form" @submit.prevent="onSubmit">
      <img src="@/assets/logo.png" alt="hbruitersport">
      <p>Log in met uw HBRuiterSport account.</p>
      <AlertBar :key="alertKey" />
      <label for="username">Email</label>
      <input type="text" name="username" v-model="form.username" id="username" />
      <label for="password">Wachtwoord</label>
      <input type="password" name="password" v-model="form.password" id="password" />
      <input type="submit" value="Inloggen" class="button" />
      <router-link :to="{name: 'RequestPasswordReset'}">Wachtwoord resetten</router-link>
    </form>
  </div>
</template>

<script>
import API from '@/libs/API';
import AlertBar from "@/components/Alerts/AlertBar";
import store from "@/store";

export default {
  name: "Login",
  components: {AlertBar},
  data() {
    return {
      form: {
        username: undefined,
        password: undefined,
      },
      alertKey: 0,
      loading: true,
    }
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.alertKey++;
      this.loading = true;
      await API.auth.login(this.form.username, this.form.password);
      if ( API.auth.isLoggedIn() ) {
        await this.$router.push({name: 'AccountDetails'});
      } else {
        await store.dispatch('messages/addErrorMessage', 'E-mailadres of wachtwoord ongeldig.');
        this.alertKey++;
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/account/login.scss";
</style>