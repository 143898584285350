<template>
  <div v-if="hasMessages" class="alert-bar">
    <Alert
      v-for="message of successMessages"
      :key="message"
      type="success"
      :message="message"
    />
    <Alert
      v-for="message of errorMessages"
      :key="message"
      type="danger"
      :message="message"
    />
  </div>
</template>

<script>
import store from "@/store";
import Alert from "@/components/Alerts/Alert";

export default {
  name: "AlertBar",
  components: { Alert },
  computed: {
    hasMessages() {
      return this.successMessages.length > 0 || this.errorMessages.length > 0;
    },
    successMessages() {
      return store.getters["messages/successMessages"];
    },
    errorMessages() {
      return store.getters["messages/errorMessages"];
    }
  },
  mounted() {
    store.dispatch("messages/clearErrorMessage");
    store.dispatch("messages/clearSuccessMessage");
  }
};
</script>