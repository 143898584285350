<template>
  <div class="products-view">
    <div class="top-bar">
      <span v-if="totalProducts > 0">Toont producten {{productOrderMin}} - {{productOrderMax}} van {{totalProducts}} resultaten</span>
      <div v-if="hasProducts" class="sort-by">
        <span>Sorteer op:</span>
        <select v-model="currentSortOrder">
          <option v-for="sort of possibleSorts" :key="sort.type" :value="sort.type">{{sort.name}}</option>
        </select>
      </div>
    </div>
    <div class="products">
      <ProductCard
          v-for="product of products"
          :key="product.id"
          :image="product.image"
          :name="product.name"
          :price="product.price"
          :slug="product.slug"
      />
      <h2 v-if="hasNoProducts">Er zijn geen producten gevonden!</h2>
    </div>
    <v-pagination v-if="hasProducts && hasMultiplePages" v-model="currentPage" :page-count="lastPage" :classes="{button: 'button pagination-button'}"/>
  </div>
</template>

<script>
import ProductCard from "@/components/Product/ProductCard";
import vPagination from 'vue-plain-pagination';

export default {
  name: "ProductsOverview",
  components: {ProductCard, vPagination},
  props: {
    totalProducts: {
      required: true,
      type: Number,
    },
    possibleSorts: {
      required: true,
      type: Array
    },
    selectedSortOrder: {
      required: true,
      type: String
    },
    products: {
      required: true,
      type: Array
    },
    value: {
      required: true,
      type: Object
    },
    lastPage: {
      required: true,
      type: Number
    },
    count: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      currentPage: this.value.page,
      currentSortOrder: this.selectedSortOrder ?? this.possibleSorts[0].type,
    }
  },
  watch: {
    currentPage() {
     this.emit();
    },
    currentSortOrder() {
      this.emit();
    },
    "value.page"() {
        this.currentPage = this.value.page;
    }
  },
  computed: {
    hasMultiplePages() {
      return this.lastPage > 1;
    },
    hasNoProducts() {
      return this.totalProducts === 0;
    },
    hasProducts() {
      return !this.hasNoProducts;
    },
    productOrderMin() {
      if ( this.count === undefined || this.currentPage === undefined ) {
        return 0;
      }

      return Math.min(((this.currentPage - 1) * this.count) + 1, this.totalProducts);
    },
    productOrderMax() {
      if ( this.count === undefined || this.totalProducts === undefined || this.currentPage === undefined ) {
        return this.totalProducts;
      }

      return Math.min(this.currentPage * this.count, this.totalProducts);
    },
  },
  methods: {
    emit() {
      this.$emit('input', {
        page: this.currentPage,
        sortOrder: this.currentSortOrder
      });
    }
  }
}
</script>

<style scoped>

</style>