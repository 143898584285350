<template>
  <div class="top-header">
    <div class="container">
      <div class="left">
        <a :href="'tel:' +  phoneNumber" class="phone">
          {{ phoneNumber }}
        </a>
        <a :href="'mailto:' + email" class="email">
          {{ email }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  data() {
    return {
      phoneNumber: '+31 (6) 216 75 794',
      email: 'info@hbruitersport.nl'
    }
  }
}
</script>