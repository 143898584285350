import Cookies from "@/libs/Cookies";
const COOKIE_NAME = "settingsCookie";

const addCookie = values => {
    Cookies.set(COOKIE_NAME, JSON.stringify(values));
};

const getCookie = () => {
    return JSON.parse(Cookies.get(COOKIE_NAME, "{}"));
};

const getFromCookie = (name, notFound = "") => {
    if (!Cookies.has(COOKIE_NAME)) {
        addCookie({});
    }

    const settingsCookie = getCookie();
    return settingsCookie[name] !== undefined ? settingsCookie[name] : notFound;
};

const addToCookie = (name, value) => {
    const cookie = getCookie();
    cookie[name] = value;
    addCookie(cookie);
};

const state = () => {
    return {
        token: Cookies.get("token"),
        orderPage: getFromCookie("orderPage", 1),
        orderPerPage: getFromCookie("orderPerPage", 10),
        showPrices: getFromCookie('showPrices', true)
    };
};

// getters
const getters = {
    token: state => state.token,
    orderPage: state => state.orderPage,
    orderPerPage: state => state.orderPerPage,
    showPrices: state => state.showPrices,
};

// actions
const actions = {
    setToken({ commit }, token) {
        commit("SET_TOKEN", { token: token });
        Cookies.set("token", token);
    },
    setOrderPage({ commit }, orderPage) {
        commit("SET_ORDER_PAGE", { orderPage: orderPage });
        addToCookie("orderPage", orderPage);
    },
    setOrderPerPage({ commit }, orderPerPage) {
        commit("SET_ORDER_PER_PAGE", { orderPerPage: orderPerPage });
        addToCookie("orderPerPage", orderPerPage);
    },
    deleteAuthToken({ commit }) {
        actions.setToken({commit}, null);
    },
    setShowPrices({ commit }, showPrices) {
        commit("SET_SHOW_PRICES", { showPrices: showPrices });
        addToCookie('showPrices', showPrices);
    }
};

// mutations
const mutations = {
    ["SET_TOKEN"](state, { token }) {
        state.token = token;
    },
    ["SET_ORDER_PAGE"](state, { orderPage }) {
        state.orderPage = orderPage;
    },
    ["SET_ORDER_PER_PAGE"](state, { orderPerPage }) {
        state.orderPerPage = orderPerPage;
    },
    ["SET_SHOW_PRICES"](state, { showPrices }) {
        state.showPrices = showPrices;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
