import base from "@/libs/API/partials/base";

const cart = {
    async get() {
        const url = base.getUrl('cart');
        return await base.get(url);
    },
    async delete(id) {
        const url = base.getUrl('cart');
        const data = {
            _method: 'delete',
            id: id
        }
        return await base.post(url, data);
    },
    async set(id, quantity) {
        const url = base.getUrl('cart');
        const data = {
            _method: 'PUT',
            id: id,
            quantity: quantity
        };
        return await base.post(url, data);
    },
    async addGroup(variants) {
        const url = base.getUrl('cart');
        return await base.post(url, parseVariants(variants));
    },
    async cleanup() {
        const url = base.getUrl('cart/cleanup');
        return await base.post(url);
    }
}

function parseVariants(variants) {
    const result = {
        items: [],
    };

    for (const variantId in variants) {
        const count = parseInt(variants[variantId]);
        if ( count > 0 ) {
            result.items.push({id: variantId, quantity: count});
        }
    }

    return result;
}

export default cart;