<template>
  <div class="product-carousel slider">
    <swiper class="swiper swiper-container-horizontal" ref="swiper" :options="swiperOptions">
      <swiper-slide v-for="product of products" :key="product.name">
        <ProductCard
          :image="product.image"
          :name="product.name"
          :slug="product.slug"
          :price="product.price"
        />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import API from "@/libs/API";
import { Swiper as SwiperClass, Pagination, Scrollbar, A11y } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

import 'swiper/css/swiper.css'
import ProductCard from "@/components/Product/ProductCard";

// install Swiper modules
SwiperClass.use([Pagination, Scrollbar, A11y]);

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: "ProductCarousel",
  components: {ProductCard, Swiper, SwiperSlide},
  props: {
    tag: {
      required: true,
      type: String,
    },
    productsPerView: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      products: [],
      swiperOptions: {
        slidesPerView: this.productsPerView,
        loop: true,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    };
  },
  created() {
    this.refreshProducts();
  },
  methods: {
    async refreshProducts() {
      let response;

      if ( this.tag === 'latest' ) {
        response = await API.products.latest();
      } else {
        response = await API.products.byTag(this.tag);
      }

      const products = response.data;
      const result = [];
      for (const product of products) {
        result.push({
          name: product.name,
          image: product.shown_image == null ? product.primary_image_url : product.shown_image.url,
          slug: product.parent_slug === undefined ? product.slug : product.parent_slug,
          price: product.price,
        })
      }
      this.products = result;
    }
  }
}
</script>

<style lang="scss">
.swiper-wrapper {
  align-items: stretch;

  .swiper-slide {
    height: auto;
  }
}
</style>