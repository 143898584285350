<template>
    <div class="login container" :class="{loading: loading}">
        <form class="login-form" @submit.prevent="onSubmit">
            <img src="@/assets/logo.png" alt="hbruitersport">
            <p>Reset uw wachtwoord bij HBRuiterSport.</p>
            <AlertBar :key="alertKey" />
            <label for="password">Wachtwoord</label>
            <input type="password" name="password" v-model="form.password" id="password" />
            <label for="password_confirm">Wachtwoord bevestigen</label>
            <input type="password" name="password_confirm" v-model="form.password_confirm" id="password_confirm" />
            <input type="submit" value="Wachtwoord instellen" class="button" />
            <router-link :to="{name: 'Login'}">Terug naar inloggen</router-link>
        </form>
    </div>
</template>

<script>
import AlertBar from "@/components/Alerts/AlertBar.vue";
import API from '@/libs/API';
import store from "@/store";

export default {
    name: "ResetPassword",
    components: {AlertBar},
    data() {
        return {
            form: {
                password: undefined,
                password_confirm: undefined,
            },
            alertKey: 0,
            loading: true,
        }
    },
    computed: {
        token() {
            return this.$route.query.token;
        },
        email() {
            return this.$route.query.email ?? undefined;
        }
    },
    mounted() {
        this.loading = false;
    },
    methods: {
        async onSubmit() {
            this.alertKey++;
            this.loading = true;
            const response = await API.auth.resetPassword(this.token, this.form.password, this.form.password_confirm, this.email);
            if (response.status === 200) {
                await store.dispatch('messages/addSuccessMessage', response.data.message);
            } else if (response.status === 422) {
                await store.dispatch('messages/addErrorMessage', response.data.message);
            } else {
                await store.dispatch('messages/addErrorMessage', 'Er ging iets mis. Probeer het later opnieuw.');
            }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/pages/account/login.scss";
</style>