<template>
    <div class="login container" :class="{loading: loading}">
        <form class="login-form" @submit.prevent="onSubmit">
            <img src="@/assets/logo.png" alt="hbruitersport">
            <p>Reset uw wachtwoord bij HBRuiterSport.</p>
            <AlertBar :key="alertKey" />
            <label for="username">Email</label>
            <input type="text" name="username" v-model="form.username" id="username" />
            <input type="submit" value="Reset aanvragen" class="button" />
            <router-link :to="{name: 'Login'}">Terug naar inloggen</router-link>
        </form>
    </div>
</template>

<script>
import AlertBar from "@/components/Alerts/AlertBar.vue";
import API from '@/libs/API';
import store from "@/store";

export default {
    name: "RequestPasswordReset",
    components: {AlertBar},
    data() {
        return {
            form: {
                username: undefined
            },
            alertKey: 0,
            loading: true,
        }
    },
    mounted() {
        this.loading = false;
    },
    methods: {
        async onSubmit() {
            this.alertKey++;
            this.loading = true;
            const response = await API.auth.requestPasswordReset(this.form.username);
            if (response.status === 200) {
                await store.dispatch('messages/addSuccessMessage', response.data.message);
            } else if (response.status === 422) {
                await store.dispatch('messages/addErrorMessage', response.data.message);
            } else {
                await store.dispatch('messages/addErrorMessage', 'Er ging iets mis. Probeer het later opnieuw.');
            }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/pages/account/login.scss";
</style>