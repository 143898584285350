import base from "@/libs/API/partials/base";

const checkout = {
    async getPaymentMethods() {
        const url = base.getUrl('payment_methods');
        return await base.get(url);
    },
    async getShippingMethods() {
        const url = base.getUrl('shipping_methods');
        return await base.get(url);
    },
    async placeOrder(data) {
        const url = base.getUrl('order');
        return await base.post(url, data);
    },
    async postAddress(address) {
        const url = base.getUrl('address');
        return await base.post(url, address);
    },
    async getAddresses() {
        const url = base.getUrl('address');
        return await base.get(url);
    },
    async getCountries() {
        const url = base.getUrl('countries');
        return await base.get(url);
    }
}

export default checkout;