<template>
  <div
    class="alert flex items-center mb-2"
    :class="{
      show: show,
      ['alert-' + type]: true,
      'alert-dismissible': dismissible
    }"
    role="alert"
  >
    {{ message }}
    <button
      v-if="dismissible"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="onClose"
    >
      &times;
    </button>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      show: true,
    }
  },
  props: {
    type: {
      type: String,
      default: () => "info"
    },
    message: {
      type: String,
      required: true
    },
    dismissible: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    onClose() {
      this.show = false;
    }
  }
};
</script>
