import products from "@/libs/API/partials/products";
import categories from "@/libs/API/partials/categories";
import auth from "@/libs/API/partials/auth";
import cart from "@/libs/API/partials/cart";
import checkout from "@/libs/API/partials/checkout";
import customer from "@/libs/API/partials/customer";
import orders from "@/libs/API/partials/orders";

const api = {
    products,
    categories,
    auth,
    cart,
    checkout,
    customer,
    orders,
}

export default api;