<template>
  <div class="footer">
    <div class="main-footer">
      <div class="container">
      <div class="column contact">
        <h2>Contact</h2>
        <ul>
          <li><a :href="'tel:' + phoneNumber">{{phoneNumber}}</a></li>
          <li><a :href="'mailto:' + email">{{email}}</a></li>
        </ul>
      </div>
      <div class="column about-us">
        <h2>Over ons</h2>
        <ul>
          <li>
            Ruim 25 jaar is ons bedrijf een toeleverancier van vele ruitersportzaken en dierenspeciaalzaken in Nederland
            en Duitsland van hoogwaardige ruitersportartikelen en huisdierartikelen
          </li>
        </ul>
      </div>
      <div class="column social">
        <h2>Social</h2>
        <ul>
          <li><a :href="'tel:' + phoneNumber">{{phoneNumber}}</a></li>
          <li><a :href="'mailto:' + email">{{email}}</a></li>
          <li>
            <a href="https://www.facebook.com/hbruitersport.nl/" target="_blank"><img src="@/assets/icons/facebook.svg" alt="facebook" /></a>
            <a href="https://www.instagram.com/henrybammens/" target="_blank"><img src="@/assets/icons/instagram.svg" alt="facebook" /></a>
          </li>
        </ul>
      </div>
    </div>
    </div>
    <div class="small-footer">
      <div class="container">
        <div class="left">
          Copyright &copy; {{ new Date().getFullYear() }} HBRuitersport
        </div>
        <div class="right">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      phoneNumber: "+31 (6) 216 75 794",
      email: "info@hbruitersport.nl",
    }
  }
}
</script>