<template>
  <div class="middle-header container">
    <router-link :to="{ name: 'Home' }" class="left logo">
      <img src="@/assets/logo.gif" :alt="companyName">
    </router-link>
    <div class="center search-bar">
      <form :action="search.url" @submit.prevent="onSearchSubmit">
        <input type="text" :placeholder="search.placeholder" v-model="search.value">
        <button type="submit"><img src="@/assets/icons/search.svg" alt="search"></button>
      </form>
    </div>
    <div class="right shopping">
      <div class="user" @mouseenter="onUserMouseOver" @mouseleave="onUserMouseOut">
        <img src="@/assets/icons/user.svg" alt="user" class="icon" @click="goToAccount"/>
        <div class="user-menu" v-show="showUserMenu">
          <ul>
            <li v-if="isLoggedIn === false"><router-link :to="{name: 'Login'}">Inloggen</router-link></li>
            <li v-if="isLoggedIn"><router-link :to="{name: 'AccountDetails'}">Mijn account</router-link></li>
            <li v-if="isLoggedIn"><router-link :to="{name: 'AccountOrders'}">Mijn bestellingen</router-link></li>
            <li v-if="isLoggedIn" class="prices">
              <span @click="togglePrices">Prijzen</span>
              <Switches v-model="showPrices" :type-bold="true" theme="custom"></Switches>
            </li>
            <li v-if="isLoggedIn"><a href="#" @click.prevent="onLogout">Uitloggen</a></li>
          </ul>
        </div>
      </div>
      <router-link v-if="isLoggedIn" class="shopping-cart" :to="{name: 'Cart'}">
        <div class="icon">
          <img src="@/assets/icons/shopping-cart.svg" alt="user"/>
          <span class="icon-blob">{{ cart.amountOfItems }}</span>
        </div>
        <div class="text">
          <span>Winkelwagen<br/></span>
          <span v-if="showPrices">{{ cart.price }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import API from '@/libs/API';
import store from "@/store";
import Switches from 'vue-switches';

export default {
  name: "MiddleHeader",
  components: {Switches},
  data() {
    return {
      img: '',
      companyName: process.env.VUE_APP_COMPANY_NAME,
      search: {
        placeholder: 'Zoeken',
        value: undefined
      },
      showUserMenu: false,
      showPrices: store.getters['main/showPrices'],
    }
  },
  watch: {
    async showPrices(value) {
      await store.dispatch('main/setShowPrices', value);
    },
    $route (){
      this.setSearchFromUrl();
    }
  },
  async created() {
    if ( this.isLoggedIn ) {
      await store.dispatch('cart/initializeCart');
    }

    this.setSearchFromUrl();
  },
  computed: {
    isLoggedIn() {
      return API.auth.isLoggedIn();
    },
    cart() {
      return {
        amountOfItems: store.getters['cart/count'],
        price: store.getters['cart/price'],
      }
    }
  },
  methods: {
    setSearchFromUrl() {
      this.search.value = this.$route.query.q ?? undefined;
    },
    onSearchSubmit() {
      if ( this.$route.query.q === this.search.value ) {
        return;
      }

      this.$router.push({name: 'Search', query: {q: this.search.value}});
    },
    togglePrices() {
      this.showPrices = !this.showPrices;
    },
    onLogout() {
      API.auth.logout();
    },
    onUserMouseOver() {
      this.showUserMenu = true;
    },
    onUserMouseOut() {
      this.showUserMenu = false;
    },
    goToAccount() {
      if ( this.isLoggedIn ) {
        this.$router.push({name: 'AccountDetails'});
      } else {
        this.$router.push({name: 'Login'});
      }
    }
  }
}
</script>

<style scoped>

</style>