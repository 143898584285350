<template>
  <div class="cart-page container" :class="{loading: loading}">
    <Breadcrumbs/>
    <h2>Winkelwagen</h2>
    <div class="cart">
      <AlertBar :key="alertBarKey" />
      <Alert v-if="canCheckout === false" :dismissible="false" type="danger" :message="'Je hebt nog niet voldaan aan het minimale orderbedrag van ' + minimalOrderAmount + '.'" />
      <p v-if="cartIsEmpty" class="cart-empty">Je winkelwagen is leeg!</p>
      <table v-else class="cart-table">
        <thead>
          <tr>
            <th></th>
            <th>Productnaam</th>
            <th v-if="showPrices">Prijs per stuk</th>
            <th>Aantal</th>
            <th><template v-if="showPrices">Subtotaal</template><template v-else>Afrekenen</template></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item of items" :key="item.id">
            <td class="image"><img :src="item.product.image.url" :alt="item.product.name" /></td>
            <td class="info">
              <h2>{{item.product.name}}</h2>
              <span>{{item.product.sku}}</span>
              <div v-for="attribute of item.product.product_attribute_values" :key="attribute.id" class="attribute">
                <span class="attribute-name">{{attribute.product_attribute.name}}: </span>
                <span class="option-name">{{attribute.name}}</span>
              </div>
            </td>
            <td v-if="showPrices">{{item.product.price}}</td>
            <td>
              <div class="cart-item-quantity">
                <form class="cart-item-quantity" @submit.prevent="onCartItemUpdate(item.product.id, item.quantity)">
                  <input v-model="item.quantity" type="number" step="1" min="0">
                  <button type="submit" class="button save-cart-item">
                    &#10003;
                  </button>
                </form>
                <button type="button" class="button delete-cart-item" @click="onCartItemDelete(item.product.id)">
                  &#10005;
                </button>
              </div>
            </td>
            <td><template v-if="showPrices">{{item.subtotal}}</template></td>
          </tr>
        </tbody>
        <tfoot>
        <tr v-if="showPrices">
          <td class="no-border"></td>
          <td class="no-border"></td>
          <td class="no-border"></td>
          <td class="no-border"></td>
          <td>BTW: {{tax}}</td>
        </tr>
          <tr v-if="showPrices">
            <td class="no-border"></td>
            <td class="no-border"></td>
            <td class="no-border"></td>
            <td class="no-border"></td>
            <td>Totaalbedrag: {{total}}</td>
          </tr>
          <tr>
            <td class="no-border"></td>
            <td class="no-border"></td>
            <td class="no-border" v-if="showPrices"></td>
            <td class="no-border"></td>
            <td><router-link :to="{name: 'Checkout'}" class="button">Afrekenen</router-link></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Breadcrumbs from "@/components/Basic/Breadcrumbs";
import API from "@/libs/API";
import AlertBar from "@/components/Alerts/AlertBar";
import Alert from "@/components/Alerts/Alert";

export default {
  name: "Cart",
  components: {Alert, AlertBar, Breadcrumbs},
  data() {
    return {
      alertBarKey: 0,
      loading: true,
    }
  },
  computed: {
    canCheckout() {
      return store.getters['cart/canCheckout']
    },
    minimalOrderAmount() {
      return store.getters['cart/minimalOrderAmount']
    },
    items() {
      return store.getters['cart/items'];
    },
    total() {
      return store.getters['cart/price'];
    },
    showPrices() {
      return store.getters['main/showPrices'];
    },
    cartIsEmpty() {
      return store.getters['cart/isEmpty'];
    },
    tax() {
      return store.getters['cart/tax'];
    }
  },
  async created() {
    if ((await store.getters['cart/initialized']) === false) {
      await store.dispatch('cart/initializeCart');
    }
    this.loading = false;
  },
  beforeDestroy() {
    store.dispatch('cart/refreshCart');
  },
  methods: {
    async onCartItemDelete(id) {
      this.loading = true;
      const response = await API.cart.delete(id);

      if (response.status >= 200 && response.status < 300) {
        await store.dispatch('messages/addSuccessMessage', 'Winkelwagen bijgewerkt!');
      } else {
        await store.dispatch('messages/addErrorMessage', 'Er ging iets mis. Probeer het opnieuw');
      }
      await store.dispatch('cart/refreshCart');
      this.alertBarKey++;
      this.loading = false;
    },
    async onCartItemUpdate(id, quantity) {
      this.loading = true;
      const response = await API.cart.set(id, quantity);

      if (response.status >= 200 && response.status < 300) {
        if ( response.data.succeeded ){
          await store.dispatch('messages/addSuccessMessage', 'Winkelwagen bijgewerkt!');
        } else {
          await store.dispatch('messages/addErrorMessage', response.data.messages.join('<br/>'));
        }
      } else {
        await store.dispatch('messages/addErrorMessage', 'Er ging iets mis. Probeer het opnieuw');
      }
      await store.dispatch('cart/refreshCart');
      this.alertBarKey++;
      this.loading = false;
    },
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/checkout/cart.scss";
</style>