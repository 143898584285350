<template>
  <div class="filters">
    <div class="filter category" v-if="categoriesShown">
      <h2 @click="toggleFilter('category')">
        <img src="@/assets/icons/chevron-down.svg" alt="open" class="icon" :class="{ open: isOpenFilter('category') }"/>
        Categorieën
      </h2>
      <ul v-show="isOpenFilter('category')">
        <li
            v-for="category of options.categories"
            :key="category.id"
            :class="{ chosen: chosen.hasOwnProperty('category') && chosen.category.includes(category.id) }"
        >
          <span class="name" @click="chooseCategory(category.id)">{{ category.name }}</span>
          <ul v-if="category.all_subcategories.length > 0" class="subcategory">
            <li
                v-for="subCategory of category.all_subcategories"
                :key="subCategory.id"
                :class="{ chosen: chosen.hasOwnProperty('category') && chosen.category.includes(subCategory.id) }"
            >
              <span class="name" @click="chooseCategory(subCategory.id)">{{ subCategory.name }}</span>
              <ul v-if="subCategory.all_subcategories.length > 0" class="subcategory">
                <li
                    v-for="subSubCategory of subCategory.all_subcategories"
                    :key="subSubCategory.id"
                    :class="{ chosen: chosen.hasOwnProperty('category') && chosen.category.includes(subSubCategory.id) }"
                >
                  <span class="name" @click="chooseCategory(subSubCategory.id)">{{ subSubCategory.name }}</span>
                  <ul v-if="subSubCategory.all_subcategories.length > 0" class="subcategory">
                    <li
                        v-for="subSubSubCategory of subSubCategory.all_subcategories"
                        :key="subSubSubCategory.id"
                        :class="{ chosen: chosen.hasOwnProperty('category') && chosen.category.includes(subSubSubCategory.id) }"
                    >
                      <span class="name" @click="chooseCategory(subSubSubCategory.id)">{{ subSubSubCategory.name }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

        </li>
      </ul>
    </div>
    <div class="filter search">
      <h2 @click="toggleFilter('search')">
        <img src="@/assets/icons/chevron-down.svg" alt="open" class="icon" :class="{ open: isOpenFilter('search') }"/>
        Zoeken op
      </h2>
      <div v-show="isOpenFilter('search')">
        <input v-model="chosen.search" placeholder="trefwoord" name="search" class="filter-search">
        <img src="@/assets/icons/cancel.svg" class="clean" alt="clean input" @click="removeSearch"/>
      </div>
    </div>
    <div class="filter price">
      <h2 @click="toggleFilter('price')">
        <img src="@/assets/icons/chevron-down.svg" alt="open" class="icon" :class="{ open: isOpenFilter('price') }"/>
        Prijs
      </h2>
      <div v-show="isOpenFilter('price')" class="range">
        <VueRangeSlider
            v-if="isOpenFilter('price')"
            v-model="chosen.price"
            :min="options.price.low"
            :max="options.price.high"
            :enable-cross="false"
            :tooltip-merge="false"
            :formatter="priceRangeFormatter"
        />
      </div>
    </div>
    <div class="filter color" v-if="false"> <!-- Disabled for now. -->
      <h2 @click="toggleFilter('color')">
        <img src="@/assets/icons/chevron-down.svg" alt="open" class="icon" :class="{ open: isOpenFilter('color') }"/>
        Kleur
      </h2>
      <ul v-show="isOpenFilter('color')">
        <li
            v-for="color of options.color"
            :key="color.id"
            @click="toggleColor(color.id)"
            :class="{ chosen: chosen.hasOwnProperty('color') && chosen.color.includes(color.id) }"
        >
          {{ color.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'
import API from '@/libs/API/index';

export default {
  name: "Filters",
  components: {VueRangeSlider},
  props: {
    value: {
      type: Object,
      required: true
    },
    categorySlug: {
      type: String,
      default: () => undefined
    }
  },
  data() {
    return {
      chosen: this.value,
      open: undefined,
      options: {
        categories: [
        ],
        price: {
          low: 0,
          high: 1000,
        },
        color: [
          {
            name: 'Oranje',
            id: 1,
          },
          {
            name: 'Grijs',
            id: 2
          },
          {
            name: 'Zwart',
            id: 3
          }
        ]
      },
    }
  },
  computed: {
    categoriesShown() {
      return this.options.categories.length > 0;
    }
  },
  watch: {
    chosen: {
      handler() {
        this.value = this.chosen;
      },
      deep: true
    },
    async categorySlug(newVal) {
      if ( this.isOpenFilter('category') ) {
        await this.toggleFilter('category');
      }
      this.chosen.category = [];

      await this.loadCategories(newVal);
    }
  },
  created() {
    if ( this.categorySlug !== undefined ) {
      this.loadCategories(this.categorySlug);
    }
  },
  methods: {
    chooseCategory(id) {
      this.value.category = [id];
    },
    toggleColor(id) {
      const index = this.value.color.indexOf(id);
      if (index > -1) {
        this.value.color.splice(index, 1);
      } else {
        this.value.color.push(id);
      }
    },
    toggleFilter(name) {
      if ( this.isOpenFilter(name) ) {
        this.open = undefined;
      } else {
        this.open = name;
      }
    },
    isOpenFilter(name) {
      return this.open === name;
    },
    removeSearch() {
      this.value.search = undefined;
    },
    priceRangeFormatter(value) {
      return "€" + value;
    },
    async loadCategories(categorySlug) {
      const response = await API.categories.getSubcategories(categorySlug);
      this.options.categories = response.data.all_subcategories;
    }
  }
}
</script>

<style scoped>

</style>