<template>
  <div class="category container" :class="{loading: loading}">
    <Breadcrumbs />
    <h1>{{name}}</h1>
    <AlertBar />
    <div class="container">
      <Filters v-model="filters" :category-slug="this.slug"/>
      <ProductsOverview
        v-if="totalProducts"
        :lastPage="lastPage"
        :possibleSorts="possibleSorts"
        :products="products"
        :totalProducts="totalProducts"
        :count="count"
        v-model="productsOverviewConfig"
       :selected-sort-order="filters.sortOrder"/>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Basic/Breadcrumbs";
import Filters from "@/components/Category/Filters";
import API from '@/libs/API';
import _ from 'lodash';
import ProductsOverview from "@/components/Product/ProductsOverview";
import localization from "@/libs/Localization";
import store from "@/store";
import AlertBar from "@/components/Alerts/AlertBar";

export default {
  name: "Category",
  components: {AlertBar, ProductsOverview, Filters, Breadcrumbs},
  data() {
    return {
      filters: {
        price: [0, 1000],
        search: "",
        category: [],
        sortOrder: 'default'
      },
      filterKey: 0,
      possibleSorts: [
        {
          type: 'default',
          name: 'Standaard',
        },
        {
          type: 'price|desc',
          name: 'Prijs aflopend'
        },
        {
          type: 'price|asc',
          name: 'Prijs oplopend'
        },
        {
          type: 'date|desc',
          name: 'Nieuwste',
        }
      ],
      products: [],
      productsOverviewConfig: {
        page: store.getters['models/categories/getLastPage'](this.slug),
        sortOrder: 'default'
      },
      lastPage: undefined,
      count: undefined,
      totalProducts: undefined,
      throttledRefreshProducts: _.throttle(() => { this.refreshProducts() }, 1000),
      loading: true,
    }
  },
  computed: {
    parsedFilters() {
      const parsed = {};
      parsed['page'] = this.productsOverviewConfig.page;
      if ('price' in this.filters) {
        parsed['min_price'] = this.filters.price[0];
        parsed['max_price'] = this.filters.price[1];
      }
      if ( this.filters.search ) {
        parsed['search'] = this.filters.search;
      }
      if ( this.filters.category.length > 0 ) {
        parsed['categories'] = this.filters.category;
      }

      if ( this.filters.sortOrder.length > 0 ) {
        const sortOrder = this.productsOverviewConfig.sortOrder.split('|');
        parsed['sort_by'] = sortOrder[0];
        parsed['sort_order'] = sortOrder[1] ?? 'asc'
      }

      return parsed;
    },
    name() {
      const object = store.getters["models/categories/getName"](this.slug);

      if ( typeof object === 'object' && object !== null && 'name' in object ) {
        return object.name;
      }

      return '';
    },
    slug() {
      return this.$route.params.slug;
    }
  },
  watch: {
    filters: {
      handler() {
        this.throttledRefreshProducts();
      },
      deep: true,
    },
    productsOverviewConfig: {
      handler() {
        this.throttledRefreshProducts();
        this.updateLastVisited();
      },
      deep: true
    },
    async slug() {
      this.productsOverviewConfig.page = 1;
    }
  },
  created() {
    this.productsOverviewConfig.page = store.getters['models/categories/getLastPage'](this.slug);
    this.throttledRefreshProducts();
    store.dispatch('models/categories/initialize');
  },
  methods: {
    async updateLastVisited() {
      await store.dispatch('models/categories/setLastPage', {categorySlug: this.slug, page: this.productsOverviewConfig.page});
    },
    async refreshProducts() {
      this.loading = true;
      // Try to use the throttledRefreshProducts data parameter instead of this function directly so we don't make calls
      // to the server too often.
      const response = await API.categories.get(this.slug, this.parsedFilters);
      const pagination = response.data;

      this.page = parseInt(pagination.current_page);
      this.totalProducts = parseInt(pagination.total);
      this.count = parseInt(pagination.per_page);
      this.lastPage = parseInt(pagination.last_page);

      const products = [];
      for (const product of pagination.data) {
        products.push({
          id: product.id,
          image: product.image === null ? '' : product.image.url,
          name: product.name,
          price: localization.prices.parseMinMaxPrice(product.min_price, product.max_price),
          slug: product.slug
        })
      }
      this.products = products;
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/category.scss";
</style>