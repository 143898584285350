import axios from "axios";
import store from "@/store";
import router from "@/router";


const base = {
    getUrl(endpoint) {
        return process.env.VUE_APP_API_LOCATION + "/" + endpoint;
    },

    async get(url, config = {}, token = true) {
        config = mergeConfig(config, token);
        try {
            return await axios.get(url, config);
        } catch (e) {
            return await this.handleFailedResponse(e.response);
        }
    },

    async post(url, data, config = {}, token = true) {
        config = mergeConfig(config, token);
        try {
            return await axios.post(url, data, config);
        } catch (e) {
            return await this.handleFailedResponse(e.response);
        }
    },

    async handleFailedResponse(response) {
        response = await response;
        if (response.status === 401) {
            await store.dispatch('main/deleteAuthToken');
            if (router.history.current.name !== 'Login') {
                await router.push({name: 'Login'});
            }
        } else if (response.status === 422) {
            return response;
        } else {
            await store.dispatch('messages/addErrorMessage', 'Er ging iets mis. Neem contact met ons op. (Status code ' + response.status + ')');
            return response;
        }
    }
}

function mergeConfig(config, token = false) {
    if (token && store.getters["main/token"] !== null && store.getters["main/token"] !== '') {
        Object.assign(config, {
            headers: { Authorization: `Bearer ${store.getters["main/token"]}` }
        });
    }

    return config;
}


export default base;