<template>
  <div class="complete container">
    <div class="centered-block">
      <img src="@/assets/logo.png" class="logo" alt="hbruitersport">
      <h1>Bedankt voor je bestelling. We gaan er zo snel mogelijk mee aan de slag!</h1>
      <router-link class="button" :to="{name: 'Home'}">
        Terug naar home
      </router-link>
    </div>
  </div>
</template>

<script>
import API from "@/libs/API";
import store from "@/store";

export default {
  name: "Complete",
  async beforeMount() {
    await API.cart.cleanup();
    await store.dispatch('cart/refreshCart');
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/checkout/complete.scss";
</style>