<template>
  <div class="container account">
    <div class="menu">
      <router-link class="menu-item" :class="{selected: isRoute('AccountDetails')}" :to="{name: 'AccountDetails'}">
        Mijn account
      </router-link>
      <router-link class="menu-item" :class="{selected: isRoute('AccountOrders') || isRoute('AccountOrder')}" :to="{name: 'AccountOrders'}">
        Mijn bestellingen
      </router-link>
      <router-link class="menu-item" :class="{selected: isRoute('AccountAddresses')}" :to="{name: 'AccountDetails'}">
        Mijn addressen
      </router-link>
      <span class="menu-item" @click="onLogout">
        Uitloggen
      </span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import API from "@/libs/API";

export default {
  name: "Layout",
  methods: {
    onLogout() {
      API.auth.logout();
    },
    isRoute(name) {
      return this.$route.name === name;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/account/layout.scss";
</style>