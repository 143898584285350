<template>
  <div id="app">
    <Header />
    <router-view class="page"/>
    <Footer />
  </div>
</template>

<style lang="scss">
@import "../node_modules/modern-normalize/modern-normalize.css";
@import "./scss/main.scss";
</style>

<script>
import Header from "./components/Basic/Header";
import Footer from "./components/Basic/Footer";

export default {
  components: {Footer, Header}
}
</script>