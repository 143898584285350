<template>
  <div class="home">
    <Slider name="home" class="slider" v-if="rendered"/>
    <div class="product-spotlight container">
      <h2 class="product-spotlight-title">Uitgelichte producten</h2>
      <tabs :options="{ useUrlFragment: false, defaultTabHash: activeTab }" @changed="onTabChanged">
        <tab name="Nieuwe producten" id="new-products" class="new-products">
          <div class="left">
            <img src="@/assets/sliders/slide-2.jpg" alt="slide-2"/>
          </div>
          <div class="right">
            <ProductCarousel :productsPerView="3" tag="latest" v-if="activeTab === '#new-products'"/>
          </div>
        </tab>
        <tab name="Uitgelichte producten" id="spotlight-products" class="new-products">
          <div class="left">
            <img src="@/assets/sliders/slide-2.jpg" alt="slide-2"/>
          </div>
          <div class="right">
            <ProductCarousel :productsPerView="3" tag="home" v-if="activeTab === '#spotlight-products'"/>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import Slider from "../components/Slider";
import ProductCarousel from "@/components/Product/ProductCarousel";
export default {
  name: 'Home',
  components: {ProductCarousel, Slider},
  data() {
    return {
      rendered: true,
      activeTab: '#spotlight-products',
    }
  },
  created() {
    this.rendered = true
  },
  methods: {
    onTabChanged(selectedTab) {
      this.activeTab = selectedTab.tab.hash;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/home.scss";
</style>
