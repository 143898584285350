<template>
  <h1>Verzoek behandelen</h1>
</template>

<script>
import moment from "moment";
import store from "@/store";
import API from "@/libs/API";

export default {
  name: "LoginAs",
  async created() {
    const search = new URLSearchParams(window.location.search);
    const expires = search.get("expires");
    const signature = search.get("signature");
    const userId = search.get("user_id");

    if (moment(expires).isBefore(moment())) {
      await store.dispatch('messages/addErrorMessage', 'De link is verlopen');
      return;
    }

    const response = await API.customer.loginAs(userId, expires, signature);
    const token = response.data.token;
    await store.dispatch('main/setToken', token);
    await this.$router.push({name: 'AccountDetails'});
  }
}
</script>

<style scoped>

</style>