import API from "@/libs/API";

const state = () => {
    return {
        initialized: false,
        categories: [],
        lastPages: {},
    };
};

// getters
const getters = {
    categories: state => state.categories,
    getName: state => slug => state.categories.find(element => element.slug === slug),
    getLastPage: state => slug => slug in state.lastPages ? state.lastPages[slug] : 1,
};

// actions
const actions = {
    async initialize({ commit }) {
        commit("SET_INITIALIZED");
        await actions.refresh({ commit });
    },
    async refresh({ commit }) {
        const response = await API.categories.getAll();
        commit("SET_CATEGORIES", { categories: response.data });
    },
    async setLastPage({ commit }, { categorySlug, page }) {
        commit("SET_LAST_PAGE", {slug: categorySlug, page: page});
    }
};

// mutations
const mutations = {
    ["SET_INITIALIZED"](state) {
        state.initialized = true;
    },
    ["SET_CATEGORIES"](state, { categories }) {
        state.categories = categories;
    },
    ["SET_LAST_PAGE"](state, { slug, page }) {
        state.lastPages[slug] = page;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
