<template>
  <ul class="subcategories">
    <template v-for="{name, slug} in subcategories[parentSlug]">
      <li :key="slug" :class="{'has-children': slug in subcategories && subcategories[slug].length > 0}">
        <router-link :to="{name: 'Category', params: {slug: slug}}">{{name}}</router-link>
        <Submenu v-if="slug in subcategories" :parentSlug="slug" :subcategories="subcategories" />
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: "Submenu",
  props: {
    subcategories: {
      type: Object,
      required: true,
    },
    parentSlug: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>

</style>