import localization from "@/libs/Localization";

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
});

const prices = {
    format(price) {
        if ( price === null || isNaN(price) ) {
            return price;
        }

        return formatter.format(price);
    },
    parseMinMaxPrice(min, max) {
        if ( min === null ) {
            return null;
        }
        if ( min === max ) {
            return localization.prices.format(min);
        }

        return localization.prices.format(min) + ' - ' + localization.prices.format(max);
    }
}



export default prices;