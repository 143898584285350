<template>
  <div>
    <label>
      Welk adres wil je gebruiken?
      <select name="payment_address_id" v-model="input.id">
        <option v-for="address of possibleAddresses" :value="address.id" :key="address.id">{{ address.name }}</option>
      </select>
    </label>
    <div class="address-input fields" v-if="input.id === undefined">
      <template v-if="exclude.includes('first-name') === false">
        <label for="first-name" class="label_first-name" :class="{'has-errors': hasErrors('first_name')}">Voornaam</label>
        <input id="first-name" v-model="input.first_name" name="first_name" class="input_first-name" :class="{'has-errors': hasErrors('first_name')}">
        <span class="error_first-name error-message" v-if="hasErrors('first_name')">{{ getErrors('first_name')}}</span>
      </template>
      <template v-if="exclude.includes('middle-name') === false">
        <label for="middle-name" class="label_middle-name" :class="{'has-errors': hasErrors('middle_name')}">Tussenvoegsel</label>
        <input id="middle-name" v-model="input.middle_name" name="middle_name" class="input_middle-name" :class="{'has-errors': hasErrors('middle_name')}">
        <span class="error_middle-name error-message" v-if="hasErrors('middle_name')">{{ getErrors('middle_name')}}</span>
      </template>
      <template v-if="exclude.includes('last-name') === false">
        <label for="last-name" class="label_last-name" :class="{'has-errors': hasErrors('last_name')}">Achternaam</label>
        <input id="last-name" v-model="input.last_name" name="last_name" class="input_last-name" :class="{'has-errors': hasErrors('last_name')}">
        <span class="error_last-name error-message" v-if="hasErrors('last_name')">{{ getErrors('last_name')}}</span>
      </template>
      <template v-if="exclude.includes('company-name') === false">
        <label for="company-name" class="label_company-name" :class="{'has-errors': hasErrors('company')}">Bedrijfsnaam</label>
        <input id="company-name" v-model="input.company_name" name="company" class="input_company-name" :class="{'has-errors': hasErrors('company')}">
        <span class="error_company error-message" v-if="hasErrors('company')">{{ getErrors('company')}}</span>
      </template>
      <template v-if="exclude.includes('email') === false">
        <label for="email" class="label_email" :class="{'has-errors': hasErrors('email')}">Email</label>
        <input id="email" type="email" v-model="input.email" name="email" class="input_email" :class="{'has-errors': hasErrors('email')}">
        <span class="error_email error-message" v-if="hasErrors('email')">{{ getErrors('email')}}</span>
      </template>
      <template v-if="exclude.includes('phone-number') === false">
        <label for="phone-number" class="label_phone-number" :class="{'has-errors': hasErrors('phone')}">Telefoon</label>
        <input id="phone-number" v-model="input.phone" name="phone" class="input_phone-number" :class="{'has-errors': hasErrors('phone')}">
        <span class="error_phone-number error-message" v-if="hasErrors('phone')">{{ getErrors('phone')}}</span>
      </template>
      <template v-if="exclude.includes('address-line-1') === false">
        <label for="address-line-1" class="label_address-line-1" :class="{'has-errors': hasErrors('address_line_1')}">Adres</label>
        <input id="address-line-1" v-model="input.address_line_1" name="address_line_1" class="input_address-line-1" :class="{'has-errors': hasErrors('address_line_1')}">
        <span class="error_address-line-1 error-message" v-if="hasErrors('address_line_1')">{{ getErrors('address_line_1')}}</span>
      </template>
      <template v-if="exclude.includes('address-line-2') === false">
        <label for="address-line-2" class="label_address-line-2" :class="{'has-errors': hasErrors('address_line_2')}">Toevoeging</label>
        <input id="address-line-2" v-model="input.address_line_2" name="address_line_1" class="input_address-line-2" :class="{'has-errors': hasErrors('address_line_2')}">
        <span class="error_address-line-2 error-message" v-if="hasErrors('address_line_2')">{{ getErrors('address_line_2')}}</span>
      </template>
      <template v-if="exclude.includes('postal-code') === false">
        <label for="postal-code" class="label_postal-code" :class="{'has-errors': hasErrors('postal_code')}">Postcode</label>
        <input id="postal-code" v-model="input.postal_code" name="postal_code" class="input_postal-code" :class="{'has-errors': hasErrors('postal_code')}">
        <span class="error_postal-code error-message" v-if="hasErrors('postal_code')">{{ getErrors('postal_code')}}</span>
      </template>
      <template v-if="exclude.includes('city') === false">
        <label for="city" class="label_city" :class="{'has-errors': hasErrors('city')}">Stad</label>
        <input id="city" v-model="input.city" name="city" class="input_city" :class="{'has-errors': hasErrors('city')}">
        <span class="error_city error-message" v-if="hasErrors('city')">{{ getErrors('city')}}</span>
      </template>
      <template v-if="exclude.includes('country') === false">
        <label for="country" class="label_country" :class="{'has-errors': hasErrors('country_id')}">Land</label>
        <select id="country" name="country_id" v-model="input.country_id" class="input_country" :class="{'has-errors': hasErrors('country_id')}">
          <option v-for="country of possibleCountries" :key="country.id" :value="country.id">{{ country.name }}</option>
        </select>
        <span class="error_country error-message" v-if="hasErrors('country_id')">{{ getErrors('country_id')}}</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressInput",
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    exclude: {
      type: Array,
      default() {
        return [];
      }
    },
    possibleCountries: {
      type: Array,
      required: true
    },
    possibleAddresses: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      default() { return {}; }
    }
  },
  data() {
    return {
      input: {
        id: this.value.id,
        first_name: this.value.first_name,
        middle_name: this.value.middle_name,
        last_name: this.value.last_name,
        company_name: this.value.company_name,
        email: this.value.email,
        phone: this.value.phone,
        address_line_1: this.value.address_line_1,
        address_line_2: this.value.address_line_2,
        postal_code: this.value.postal_code,
        city: this.value.city,
        country_id: this.value.country_id,
      }
    }
  },
  watch: {
    input: {
      handler(input) {
        this.emitInput(input);
      },
      deep: true
    },
    "input.first_name"() {
      this.removeError('first_name');
    },
    "input.middle_name"() {
      this.removeError('middle_name');
    },
    "input.last_name"() {
      this.removeError('last_name');
    },
    "input.company_name"() {
      this.removeError('company_name');
    },
    "input.email"() {
      this.removeError('email');
    },
    "input.phone"() {
      this.removeError('phone');
    },
    "input.address_line_1"() {
      this.removeError('address_line_1');
    },
    "input.address_line_2"() {
      this.removeError('address_line_2');
    },
    "input.postal_code"() {
      this.removeError('postal_code');
    },
    "input.city"() {
      this.removeError('city');
    },
    "input.country_id"() {
      this.removeError('country_id');
    },
  },
  methods: {
    emitInput(input) {
      if (input === undefined) {
        input = this.input;
      }

      this.$emit('input', input);
    },
    hasErrors(name) {
      return this.errors && name in this.errors;
    },
    getErrors(name) {
      if ( !this.hasErrors(name) ) {
        return null;
      }

      return this.errors[name].join();
    },
    removeError(name) {
      delete this.errors[name];
    }
  }
}
</script>

<style scoped>

</style>