import API from "@/libs/API";
import localization from "@/libs/Localization";

const state = () => {
    return {
        initialized: false,
        customer: undefined
    };
};

// getters
const getters = {
    initialized: state => state.initialized,
    name: state => state.customer ? state.customer.name : undefined,
    orders: state => state.customer ? state.customer.orders : [],
};

// actions
const actions = {
    async initializeCustomer({getters, dispatch}) {
        if ( await getters["initialized"] === false ) {
            dispatch('updateCustomer');
        }
    },
    async updateCustomer({commit}) {
        const response = await API.customer.get();
        commit("SET_CUSTOMER", {customer: response.data});
    },
};

// mutations
const mutations = {
    ["SET_CUSTOMER"](state, { customer }) {
        state.customer = {
            name: customer.name,
            orders: parseOrders(customer.orders),
        };
        state.initialized = true;
    }
};

function parseOrders(orders) {
    const result = [];
    for (const order of orders) {
        result.push({
            id: order.id,
            createdAt: localization.datetime.parse(order.created_at),
            orderStatus: order.order_status.name,
            total: localization.prices.format(order.total),
        })
    }
    return result;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};