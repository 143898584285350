import base from "@/libs/API/partials/base";
import store from "@/store";
import {EventBus} from "@/event-bus/event-bus";

const auth = {
    async login(username, password) {
        const url = base.getUrl("login");

        const response = await base.post(
            url,
            {
                email: username,
                password: password
            },
            {},
            false
        );

        if (response.status === 200 && response.data.success === true) {
            await store.dispatch("main/setToken", response.data.token);
            EventBus.$emit('loggedIn');
        }

        return response;
    },
    async logout() {
        await store.dispatch('main/setToken', null);
        EventBus.$emit('loggedOut');
    },
    async requestPasswordReset(username) {
        const url = base.getUrl("account/request-password-reset");

        return await base.post(
            url,
            {
                email: username
            },
            {},
            false
        );
    },
    async resetPassword(token, password, password_confirm, email) {
        const url = base.getUrl("account/reset-password");

        return await base.post(
            url,
            {
                token: token,
                password: password,
                password_confirmation: password_confirm,
                email: email
            },
            {},
            false
        );
    },
    isLoggedIn() {
        return store.getters['main/token'] !== null && store.getters['main/token'] !== '';
    }
}

export default auth;