<template>
  <div class="menu-header container">
    <ul>
      <li
          class="all"
          @mouseenter="onAllCategoriesMouseEnter"
          @mouseleave="onAllCategoriesMouseLeave"
      >
        <span>Alle Categorieën</span>
        <div class="mega-menu" :class="{show: megaMenuShown}">
          <ul>
            <li v-for="{name, slug} of topCategories" v-bind:key="name" :class="{'has-children': slug in subcategories}">
              <router-link :to="{name: 'Category', params: {slug: slug}}">{{name}}</router-link>
              <Submenu :parentSlug="slug" :subcategories="subcategories" />
            </li>
          </ul>
        </div>
      </li>
      <li v-for="{name, slug} of headerCategories" v-bind:key="name">
        <router-link :to="{name: 'Category', params: {slug: slug}}">{{name}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import API from '@/libs/API';
import Submenu from "@/components/Basic/Header/Submenu";
import {EventBus} from "@/event-bus/event-bus";

export default {
  name: "Menu",
  components: {Submenu},
  data() {
    return {
      topCategories: [],
      headerCategories: [],
      allCategories: [],
      subcategories: [],
      megaMenuShown: false,
    }
  },
  created() {
    this.refreshCategories();
    this.refreshHeaderCategories();

    EventBus.$on('loggedIn', () => {
      this.refreshCategories();
      this.refreshHeaderCategories();
    })

    EventBus.$on('loggedOut', () => {
      this.refreshCategories();
      this.refreshHeaderCategories();
    })
  },
  methods: {
    onAllCategoriesMouseEnter() {
      this.megaMenuShown = true;
    },
    onAllCategoriesMouseLeave() {
      this.megaMenuShown = false;
    },
    async refreshCategories() {
      const response = await API.categories.getAll();
      const categories = response.data;
      const topCategories = [];
      const allCategories = [];
      const subcategories = {};
      for (const category of categories) {
        const object = {
          name: category.name,
          slug: category.slug,
        };

        if ( category.parent_id === null) {
          topCategories.push(object);
        } else {
          if ( !(category.parent.slug in subcategories) ) {
            subcategories[category.parent.slug] = [];
          }
          subcategories[category.parent.slug].push(object);
        }
      }
      this.topCategories = topCategories;
      this.allCategories = allCategories;
      this.subcategories = subcategories;
    },
    async refreshHeaderCategories() {
      const response = await API.categories.header();
      const categories = response.data;
      const headerCategories = [];

      for (const category of categories) {
        headerCategories.push({
          name: category.name,
          slug: category.slug,
        });
      }

      this.headerCategories = headerCategories;
    }

  }
}
</script>

<style scoped>

</style>