import base from "@/libs/API/partials/base";

const customer = {
    async get() {
        const url = base.getUrl('account/me');
        return await base.get(url);
    },

    async loginAs(userId, expires, signature) {
        const url = base.getUrl('account/' + userId +'/auth-token?expires=' + expires + '&signature=' + signature);
        return await base.get(url);
    }
}

export default customer;