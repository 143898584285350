<template>
  <div class="breadcrumbs">
    <router-link v-for="{name, to} in breadcrumbs" :key="name" :to="to">
      {{name}}
      <span v-if="isNotLast(name)">{{separator}}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    separator: {
      default() { return '/'; }
    }
  },
  computed: {
    breadcrumbs() {
      return this.$route.meta.breadcrumbs(this.$route.params);
    }
  },
  methods: {
    isNotLast(name) {
      return this.breadcrumbs.slice(-1).pop().name !== name;
    }
  }
}
</script>