import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/page/Home.vue'
import Category from "@/page/Catalog/Category";
import Product from "@/page/Catalog/Product";
import Login from "@/page/Account/Login";
import Details from "@/page/Account/Details";
import AccountLayout from "@/page/Account/Layout";
import AccountOrder from "@/page/Account/Order";
import API from "@/libs/API";
import Cart from "@/page/Checkout/Cart";
import Checkout from "@/page/Checkout/Checkout";
import Search from "@/page/Catalog/Search";
import Complete from "@/page/Checkout/Complete";
import Orders from "@/page/Account/Orders";
import LoginAs from "@/page/Account/LoginAs";
import RequestPasswordReset from "@/page/Account/RequestPasswordReset.vue";
import ResetPassword from "@/page/Account/ResetPassword.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    name: 'Login-as',
    path: '/login-as',
    component: LoginAs
  },
  {
    name: 'Login',
    path: '/account/login',
    component: Login
  },
  {
    name: 'RequestPasswordReset',
    path: '/account/request-password-reset',
    component: RequestPasswordReset
  },
  {
    name: 'ResetPassword',
    path: '/account/reset-password',
    component: ResetPassword
  },
  {
    path: '/account',
    component: AccountLayout,
    children: [
      {
        name: 'AccountDetails',
        component: Details,
        path: '/'
      },
      {
        name: "AccountOrders",
        component: Orders,
        path: '/order',
      },
      {
        name: "AccountOrder",
        component: AccountOrder,
        path: '/order/:id'
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Search',
    path: '/search',
    component: Search,
  },
  {
    name: 'Cart',
    path: "/cart",
    component: Cart,
    meta: {
      requiresAuth: true,
      breadcrumbs() {
        return [
          {
            name: 'Home',
            to: {name: 'Home'}
          },
          {
            name: 'Cart',
            to: {name: 'Cart'}
          }
        ]
      }
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/checkout/complete',
    name: 'checkout.complete',
    component: Complete
  },
  {
    path: '/category/:slug',
    name: 'Category',
    component: Category,
    meta: {
      breadcrumbs(routerParams) {
        return [
          {
            name: 'Home',
            to: {name: 'Home'}
          },
          {
            name: routerParams.slug,
            to: {name: 'Category', params: {slug: routerParams.slug}}
          }
        ]
      }
    }
  },
  {
    path: '/product/:slug',
    name: 'Product',
    component: Product,
    meta: {
      breadcrumbs(routerParams) {
        return [
          {
            name: 'Home',
            to: {name: 'Home'}
          },
          {
            name: routerParams.slug,
            to: {name: 'Product', params: {slug: routerParams.slug}}
          }
        ];
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!API.auth.isLoggedIn()) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
