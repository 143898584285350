<template>
  <swiper class="swiper banner" ref="swiper" :options="swiperOption">
    <swiper-slide><img src="@/assets/sliders/slide-1.jpg" alt="slide 1"/></swiper-slide>
    <swiper-slide><img src="@/assets/sliders/slide-2.jpg" alt="slide 2"/></swiper-slide>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper as SwiperClass, Navigation, Pagination, Scrollbar, A11y } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

import 'swiper/css/swiper.css'

// install Swiper modules
SwiperClass.use([Navigation, Pagination, Scrollbar, A11y]);

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: "Slider",
  components: {Swiper, SwiperSlide},
  data() {
    return {
      swiperOption: {
        width: this.vw,
        loop: true,
        slidesPerView: 1,
        autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  props: {
    name: {
      required: true,
    }
  },
  computed: {
    vw() {
      return document.body.clientWidth;
    }
  },
}
</script>

<style scoped>

</style>