<template>
  <div class="order-view" :class="{loading: loading}">
    <template v-if="order">
      <h1 class="title">Bestelling #{{ order.id }}</h1>
      <table class="details">
        <tr>
          <td>Verzendmethode:</td>
          <td>{{ order.shippingMethod }}</td>
        </tr>
        <tr>
          <td>Betaalmethode:</td>
          <td>{{ order.paymentMethod }}</td>
        </tr>
      </table>
      <div class="address shipping_address">
        <h2>Verzendadres</h2>
        <table>
          <tr>
            <td>Voornaam:</td>
            <td>{{ order.shippingAddress.first_name }}</td>
          </tr>
          <tr>
            <td>Tussenvoegsel:</td>
            <td>{{ order.shippingAddress.middle_name }}</td>
          </tr>
          <tr>
            <td>Achternaam:</td>
            <td>{{ order.shippingAddress.last_name }}</td>
          </tr>
          <tr>
            <td>Bedrijf:</td>
            <td>{{ order.shippingAddress.company }}</td>
          </tr>
          <tr>
            <td>Adres regel 1:</td>
            <td>{{ order.shippingAddress.address_line_1 }}</td>
          </tr>
          <tr>
            <td>Adres regel 2:</td>
            <td>{{ order.shippingAddress.address_line_2 }}</td>
          </tr>
          <tr>
            <td>Stad:</td>
            <td>{{ order.shippingAddress.city }}</td>
          </tr>
          <tr>
            <td>Postcode:</td>
            <td>{{ order.shippingAddress.postal_code }}</td>
          </tr>
          <tr>
            <td>Land:</td>
            <td>{{ order.shippingAddress.country.name }}</td>
          </tr>
          <tr>
            <td>Telefoonnummer:</td>
            <td>{{ order.shippingAddress.phone }}</td>
          </tr>
        </table>
      </div>
      <div class="address payment_address">
        <h2>Factuuradres</h2>
        <table>
          <tr>
            <td>Voornaam:</td>
            <td>{{ order.paymentAddress.first_name }}</td>
          </tr>
          <tr>
            <td>Tussenvoegsel:</td>
            <td>{{ order.paymentAddress.middle_name }}</td>
          </tr>
          <tr>
            <td>Achternaam:</td>
            <td>{{ order.paymentAddress.last_name }}</td>
          </tr>
          <tr>
            <td>Bedrijf:</td>
            <td>{{ order.paymentAddress.company }}</td>
          </tr>
          <tr>
            <td>Adres regel 1:</td>
            <td>{{ order.paymentAddress.address_line_1 }}</td>
          </tr>
          <tr>
            <td>Adres regel 2:</td>
            <td>{{ order.paymentAddress.address_line_2 }}</td>
          </tr>
          <tr>
            <td>Stad:</td>
            <td>{{ order.paymentAddress.city }}</td>
          </tr>
          <tr>
            <td>Postcode:</td>
            <td>{{ order.paymentAddress.postal_code }}</td>
          </tr>
          <tr>
            <td>Land:</td>
            <td>{{ order.paymentAddress.country.name }}</td>
          </tr>
          <tr>
            <td>Telefoonnummer:</td>
            <td>{{ order.paymentAddress.phone }}</td>
          </tr>
        </table>
      </div>
      <div class="order-lines">
        <h2>Bestelregels</h2>
        <div>
          <table>
            <thead>
              <tr>
                <th>Productafbeelding</th>
                <th>Productnaam</th>
                <th>SKU</th>
                <th v-if="showPrices">Prijs</th>
                <th>Aantal</th>
                <th v-if="showPrices">Totaal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="orderLine of order.orderLines" :key="orderLine.id" class="order-line">
                <td>
                  <img :src="orderLine.image" :alt="orderLine.name" class="image" v-if="orderLine.image !== null"/>
                </td>
                <td>{{orderLine.name}}</td>
                <td>{{orderLine.sku}}</td>
                <td v-if="showPrices">{{orderLine.price}}</td>
                <td>{{orderLine.quantity}}</td>
                <td v-if="showPrices">{{orderLine.total}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="order-histories">
        <h2>Historie</h2>
        <table>
          <thead>
            <tr>
              <th>Datum</th>
              <th>OrderStatus</th>
              <th>Opmerking</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orderHistory of order.orderHistories" :key="orderHistory.date">
              <td>{{orderHistory.createdAt}}</td>
              <td>{{orderHistory.status}}</td>
              <td>{{orderHistory.comment}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import API from "@/libs/API";
import localization from "@/libs/Localization";
import store from "@/store";

export default {
  name: "Order",
  created() {
    this.refreshOrder();
  },
  data() {
    return {
      order: undefined,
      loading: true,
    };
  },
  computed: {
    showPrices() {
      return store.getters["main/showPrices"];
    }
  },
  methods: {
    async refreshOrder() {
      this.loading = true;

      const response = await API.orders.get(this.$route.params.id);
      const order = response.data;

      this.order = {
        id: order.id,
        shippingMethod: order.shipping_method.name,
        paymentMethod: order.payment_method.description,
        shippingAddress: this.parseAddress(order.shipping_address),
        paymentAddress: this.parseAddress(order.payment_address),
        orderLines: this.parseOrderLines(order.order_lines),
        orderHistories: this.parseOrderHistories(order.order_histories),
      }

      this.loading = false;
    },
    parseAddress(address) {
      return address;
    },
    parseOrderLines(orderLines) {
      const result = [];

      for (const orderLine of orderLines) {
        console.log(orderLine);
        result.push({
          id: orderLine.id,
          image: orderLine.product !== null ? orderLine.product.shown_image.url : null,
          name: orderLine.name,
          sku: orderLine.sku,
          price: localization.prices.format(orderLine.unitPrice),
          quantity: orderLine.quantity,
          total: localization.prices.format(orderLine.unitPrice * orderLine.quantity)
        });
      }

      return result;
    },
    parseOrderHistories(orderHistories) {
      const result = [];

      for (const orderHistory of orderHistories) {
        result.push({
          createdAt: localization.datetime.parse(orderHistory.created_at),
          status: orderHistory.order_status.name,
          comment: orderHistory.comment
        });
      }

      return result;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/account/order";
</style>