<template>
  <div class="account-details">
    <h1>
      Mijn account
    </h1>
    <p>Hallo {{name}}</p>
    <p>Op deze pagina kan je jouw <router-link :to="{name: 'AccountOrders'}">eerder geplaatste orders</router-link> en de status er van bekijken en je addressen verwijderen.</p>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Details",
  computed: {
    name() {
      return store.getters["customer/name"];
    }
  },
  created() {
    store.dispatch('customer/initializeCustomer');
  },
}
</script>

<style lang="scss">
@import "@/scss/pages/account/details.scss";
</style>