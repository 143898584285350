<template>
  <router-link :to="{name: 'Product', params: {slug: slug}}" class="product-card">
    <img :src="shownImage" :alt="name"/>
    <div class="text">
      <h2>{{name}}</h2>
      <span v-if="price !== null && showPrices">{{formattedPrice}}</span>
    </div>
  </router-link>
</template>

<script>
import store from "@/store";
import localization from "@/libs/Localization";

export default {
  name: "ProductCard",
  props: {
    name: {
      required: true,
      type: String,
    },
    image: {
      required: true,
      type: String,
    },
    slug: {
      required: true,
      type: String,
    },
    price: {
      required: false,
      type: String,
    },
  },
  computed: {
    showPrices() {
      return store.getters['main/showPrices'];
    },
    formattedPrice() {
      return localization.prices.format(this.price);
    },
    shownImage() {
      if ( this.image !== '' ) {
        return this.image;
      }

      console.log('TES');

      return require('@/assets/no-image-available.png');
    }
  }
}
</script>

<style scoped>

</style>