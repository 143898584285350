<template>
  <div class="checkout container" :class="{loading: loading}">
    <form class="checkout-form" @submit.prevent="submitForm">
      <span class="alert alert-danger show" v-if="hasErrors('cart')">{{ getErrors('cart')}}</span>
      <fieldset class="payment-address">
        <h2>Betaalgegevens</h2>
        <div class="container">
          <AddressInput :possible-addresses="availableAddresses" :possible-countries="availableCountries" v-model="form.payment_address" v-if="addressesLoaded" :errors="errors.payment_address"/>
        </div>
      </fieldset>
      <fieldset class="shipping-address">
        <h2>Verzendgegevens</h2>
        <div class="container">
          <input type="checkbox" v-model="form.billing_same_as_payment_address" name="billing_same_as_payment_address" id="billing_same_as_payment_address">
          <label for="billing_same_as_payment_address"> Verzendgegevens zelfde als betaalgegevens</label>
          <AddressInput :possible-addresses="availableAddresses" :possible-countries="availableCountries" v-model="form.shipping_address" v-if="addressesLoaded" v-show="!form.billing_same_as_payment_address" :errors="errors.shipping_address"/>
        </div>
      </fieldset>
      <fieldset class="payment-method" :class="{hasErrors: hasErrors('payment_method_id')}">
        <h2>Betaalmethode</h2>
        <div class="container">
          <span class="error-message" v-if="hasErrors('payment_method_id')">{{ getErrors('payment_method_id') }}</span>
          <div class="payment-methods radio-buttons">
            <span v-if="availablePaymentMethods.length === 0">We hebben op dit moment geen betaalmethodes beschikbaar. Neem contact met ons op.</span>
            <label v-for="paymentMethod in availablePaymentMethods" :key="paymentMethod.id + '-label'" class="payment-method radio-button">
              <input
                  type="radio"
                  v-model="form.payment_method"
                  name="payment_method"
                  :value="paymentMethod.id"
                  :id="paymentMethod.id + '-input'"
              />
              <img v-if="paymentMethod.img" :src="paymentMethod.img" :alt="paymentMethod.name"/>
              {{paymentMethod.name}}
            </label>
          </div>
        </div>
      </fieldset>
      <fieldset class="shipping-method" :class="{hasErrors: hasErrors('shipping_method_id')}">
        <h2>Verzendmethode</h2>
        <div class="container">
          <span class="error-message" v-if="hasErrors('shipping_method_id')">{{ getErrors('shipping_method_id') }}</span>
          <div class="payment-methods radio-buttons">
            <label v-for="shippingMethod in availableShippingMethods" :key="shippingMethod.id + '-label'" class="shipping-method radio-button">
              <input
                  type="radio"
                  v-model="form.shipping_method"
                  name="shipping_method"
                  :value="shippingMethod.id"
                  :id="shippingMethod.id + '-input'"
              />
              <img v-if="shippingMethod.img" :src="shippingMethod.img" :alt="shippingMethod.name"/>
              {{shippingMethod.name}}
            </label>
          </div>
        </div>
      </fieldset>
    </form>
    <div class="cart-overview">
      <h2>Uw bestelling</h2>
      <table class="summary custom-styling" v-if="showPrices">
        <tbody>
          <tr>
            <td>Winkelwagen totaal</td>
            <td>{{cartPrice}}</td>
          </tr>
          <tr>
            <td>{{shippingMethodData.name}}</td>
            <td>{{ shippingMethodData.cost }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Subtotaal</td>
            <td>{{ subtotal }}</td>
          </tr>
          <tr>
            <td>BTW</td>
            <td>{{ tax }}</td>
          </tr>
          <tr>
            <td>Bestelling totaal</td>
            <td>{{ totalPrice }}</td>
          </tr>
        </tfoot>
      </table>
      <div class="cart">
        <span class="amount">{{amount}} artikelen in winkelwagen</span>
        <table class="items custom-styling">
          <tr v-for="item of items" :key="item.id" class="item">
            <td><img class="image" :src="item.product.image.url" :alt="item.product.name"></td>
            <td>{{item.product.name}} &times; {{item.quantity}}</td>
            <td v-if="showPrices">{{item.subtotal}}</td>
          </tr>
        </table>
      </div>
      <button @click.prevent="submitForm" class="button">Afrekenen</button>
    </div>
  </div>
</template>

<script>
import AddressInput from "@/components/Checkout/AddressInput";
import store from "@/store";
import API from "@/libs/API";
import localization from "@/libs/Localization";

export default {
  name: "Checkout",
  components: {AddressInput},
  data() {
    return {
      form: {
        payment_address: {
          id: undefined,
          first_name: undefined,
          middle_name: undefined,
          last_name: undefined,
          company_name: undefined,
          email: undefined,
          phone: undefined,
          address_line_1: undefined,
          address_line_2: undefined,
          postal_code: undefined,
          city: undefined,
          country_id: undefined,
        },
        billing_same_as_payment_address: true,
        shipping_address: {
          id: undefined,
          first_name: undefined,
          middle_name: undefined,
          last_name: undefined,
          company_name: undefined,
          email: undefined,
          phone: undefined,
          address_line_1: undefined,
          address_line_2: undefined,
          postal_code: undefined,
          city: undefined,
          country_id: undefined,
        },
        shipping_method: undefined,
        payment_method: undefined,
      },
      availablePaymentMethods: [],
      availableShippingMethods: [],
      availableAddresses: [],
      availableCountries: [],
      addressesLoaded: false,
      loading: true,
      errors: {}
    }
  },
  watch: {
    "form.shipping_method"() {
      this.removeError('shipping_method_id');
    },
    "form.payment_method"() {
      this.removeError('payment_method_id');
    }
  },
  computed: {
    cartIsEmpty() {
      return store.getters['cart/isEmpty'];
    },
    showPrices() {
      return store.getters['main/showPrices'];
    },
    cartPrice() {
      return store.getters['cart/price'];
    },
    canCheckout() {
      return store.getters['cart/initialized'] === false || store.getters['cart/canCheckout'];
    },
    minimalOrderAmount() {
      return store.getters['cart/minimalOrderAmount'];
    },
    items() {
      return store.getters['cart/items'];
    },
    amount() {
      return store.getters['cart/count'];
    },
    tax() {
      return store.getters['cart/tax']
    },
    subtotal() {
      return store.getters['cart/subtotal']
    },
    totalPrice() {
      return localization.prices.format(store.getters["cart/rawCartPrice"] + this.shippingMethodData.rawCost);
    },
    shippingMethodData() {
      if ( this.form.shipping_method === undefined ) {
        return {
          id: undefined,
          name: 'Geen verzendmethode geselecteerd',
          cost: localization.prices.format(0),
          rawCost: 0,
        }
      }

      return this.availableShippingMethods.filter(method => method.id === this.form.shipping_method).pop();
    },
    flattenedForm() {
      const result = {};

      result.payment_address_id = this.form.payment_address.id;
      result.payment_method_id = this.form.payment_method;
      result.shipping_address_id = this.form.shipping_address.id;
      result.shipping_method_id = this.form.shipping_method

      return result;
    }
  },
  methods: {
    hasErrors(field) {
      return field in this.errors;
    },
    getErrors(field) {
      if ( !this.hasErrors(field) ) {
        return null;
      }

      return this.errors[field].join();
    },
    removeError(field) {
      delete this.errors[field];
    },
    async refreshPaymentMethods() {
      const response = await API.checkout.getPaymentMethods();
      const paymentMethods = [];
      for (const paymentMethod of response.data.methods) {
        const imageUrl = paymentMethod.image === null ? '' : paymentMethod.image.url;
        paymentMethods.push({
          id: paymentMethod.id,
          name: paymentMethod.description,
          img: imageUrl
        });
      }
      this.availablePaymentMethods = paymentMethods;

      if ( this.availablePaymentMethods.length === 1 ) {
        this.form.payment_method = this.availablePaymentMethods[0].id;
      }
    },
    async refreshShippingMethods() {
      const response = await API.checkout.getShippingMethods();
      const shippingMethods = [];
      for (const shippingMethod of response.data.methods) {
        const imageUrl = shippingMethod.image === undefined ? '' : shippingMethod.image.url;
        shippingMethods.push({
          id: shippingMethod.id,
          name: shippingMethod.name,
          img: imageUrl,
          cost: localization.prices.format(shippingMethod.cost),
          rawCost: shippingMethod.cost
        })
      }
      this.availableShippingMethods = shippingMethods;

      if ( this.availableShippingMethods.length === 1 ) {
        this.form.shipping_method = this.availableShippingMethods[0].id;
      }
    },
    async refreshAddresses() {
      const response = await API.checkout.getAddresses();
      const addresses = [];
      for(const address of response.data.addresses) {
        addresses.push({
          id: address.id,
          name: address.address_line_1 + ', ' + address.postal_code + " (" + address.email + ")"
        })
      }
      addresses.push({
        id: undefined,
        name: 'Nieuw adres',
      })
      this.availableAddresses = addresses
      this.form.payment_address.id = addresses[0].id;
      this.form.shipping_address.id = addresses[0].id;
      this.addressesLoaded = true;
    },
    async refreshAvailableCountries() {
      const response = await API.checkout.getCountries();
      const countries = [];
      const countriesArray = ( Array.isArray(response.data.countries) ? response.data.countries : Object.values(response.data.countries) );
      for(const country of countriesArray) {
        countries.push({
          id: country.id,
          name: country.name
        })
      }
      this.availableCountries = countries;
    },
    async submitForm() {
      this.loading = true;
      if ( this.flattenedForm.payment_address_id === undefined ) {
        const id = await this.postAddress(this.form.payment_address);

        if ( typeof id === 'object' ) {
          this.errors.payment_address = id;
        } else {
          await this.refreshAddresses();
          this.form.payment_address.id = id;
        }
      }

      if ( this.flattenedForm.shipping_address_id === undefined ) {
        const id = await this.postAddress(this.form.shipping_address);

        if ( typeof id === 'object' ) {
          this.errors.shipping_address = id;
        } else {
          await this.refreshAddresses();
          this.form.shipping_address.id = id;
        }
      }

      if ( Object.keys(this.errors).length > 0 ) {
        this.loading = false;
        return;
      }

      const response = await API.checkout.placeOrder(this.flattenedForm);
      if ( response.data.succeeded ) {
        window.location.href = response.data.redirect_url;
      } else {
        this.errors = response.data.errors;
      }

      this.loading = false;
    },
    async postAddress(address) {
      const response = await API.checkout.postAddress(address);

      if ( response.data.succeeded === true ) {
        return response.data.address.id;
      } else {
        return response.data.errors;
      }
    }
  },
  async created() {
    await store.dispatch('cart/initializeCart');
    if ( this.cartIsEmpty || this.canCheckout === false) {
      await this.$router.push({name: 'Cart'});
      return;
    }
    await this.refreshShippingMethods();
    await this.refreshPaymentMethods();
    await this.refreshAddresses();
    await this.refreshAvailableCountries();
    this.loading = false;
  }
}
</script>

<style lang="scss">
@import "@/scss/pages/checkout/checkout.scss";
</style>